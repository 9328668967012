import SVG from 'react-inlinesvg';

import * as S from './styles';

type MerchantNotFoundProps = {
  onChangeAddress: () => void;
};

export const MerchantNotFound: React.VFC<MerchantNotFoundProps> = ({ onChangeAddress }) => {
  return (
    <S.Wrapper>
      <SVG src="/images/address/missing_location.svg" />
      <S.Title>Não encontramos uma loja próxima a você</S.Title>
      <S.Description>Poxa, ainda não atendemos no seu endereço. Deseja tentar outro?</S.Description>
      <S.TextLink onClick={onChangeAddress}>Trocar endereço</S.TextLink>
    </S.Wrapper>
  );
};

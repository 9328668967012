import { Drawer as PomodoroDrawer } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

export const Drawer = styled(PomodoroDrawer)`
  height: 100%;
  margin: 0;
`;

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space.large};
  position: relative;
  height: 100%;
`;

import { groceriesApiBffURL } from "@whitelabel-webapp/shared/config";
import axios, { AxiosInstance } from "axios";
import { ChainResponse, ClosestMerchantsResponse } from "./types";

export class Chain {
  static groceriesApiBffClient: AxiosInstance;

  static initGroceriesApiBffClient(): void {
    Chain.groceriesApiBffClient = axios.create({
      baseURL: groceriesApiBffURL,
    });
  }

  static async getChainByHost(name: string) {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    const { data } = await Chain.groceriesApiBffClient.get<ChainResponse>(
      `/v2/chain/by-host/${name}`,
      {
        params: {
          host: name,
        },
      },
    );

    return data;
  }

  static async getChainBySlug(slug: string) {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    function isValidSlug(slug: string) {
      const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
      return slugRegex.test(slug);
    }

    if (!isValidSlug(slug)) {
      throw new Error("Invalid slug");
    }

    const { data } = await Chain.groceriesApiBffClient.get<ChainResponse>(
      `/chain/by-slug`,
      {
        params: {
          slug: slug,
        },
      },
    );

    return data;
  }

  static async getClosestMerchants(
    name: string,
    latitude: number,
    longitude: number,
  ) {
    if (!Chain.groceriesApiBffClient) {
      Chain.initGroceriesApiBffClient();
    }

    const { data } =
      await Chain.groceriesApiBffClient.get<ClosestMerchantsResponse>(
        `/v2/merchant/chain/${name}`,
        {
          params: {
            lat: latitude,
            long: longitude,
          },
        },
      );

    return data;
  }

  static fromApi(rawMerchantChain: ChainResponse): Chain {
    return new Chain(
      rawMerchantChain.id,
      rawMerchantChain.name ?? rawMerchantChain.slug,
      rawMerchantChain.slug,
      rawMerchantChain.logoUrl,
      rawMerchantChain.primaryColor,
      rawMerchantChain.bannerUrl,
      rawMerchantChain.gaId,
      rawMerchantChain.redirectMerchant,
    );
  }

  constructor(
    public uuid: string,
    public name: string,
    public slug: string,
    public logoUrl: string,
    public primaryColor: string,
    public banner?: string,
    public gaId?: string,
    public redirectMerchant?: string,
  ) {}

  async getClosestMerchant(latitude: number, longitude: number) {
    const closestMerchants = await Chain.getClosestMerchants(
      this.slug,
      latitude,
      longitude,
    );

    if (!closestMerchants?.merchants.length) return;

    return closestMerchants.merchants.find((merchant) => Boolean(merchant.id));
  }

  getPrivacyPolicyLink() {
    return "https://privacidade.ifood.com.br/privacidade-clientes";
  }

  hasCustomizedSettings() {
    return Boolean(this.banner);
  }
}

import styled from "styled-components";

export const Search = styled.div`
  div:has(> [role="combobox"]) {
    position: relative;

    > :last-child {
      top: calc(100% + 2px);
      position: absolute;
      width: calc(100% - 2px);

      :not(:has(li)) {
        border: solid 1px transparent;
      }
    }
  }

  [role="combobox"] > :first-child {
    background-color: #f7f7f7;
    border-radius: 8px;
    border-color: #f7f7f7;
    width: calc(100% - 2px);
    margin: auto;

    > :first-child {
      background-color: #f7f7f7;
      border: none;

      div:first-child {
        margin-left: 10px;
        padding-right: 0px;
        color: ${({ theme }) => theme.colors.black};
      }

      input {
        font-size: 16px;
        background-color: #f7f7f7;
      }
    }

    > :last-child {
      margin-top: 1px;
    }
  }
`;

import {
  AutocompleteAddress,
  Coordinate,
} from "@whitelabel-webapp/address/shared/models";
import { Heading, Text } from "@whitelabel-webapp/shared/design-system";
import { useMemo } from "react";
import { usePosition } from "use-position";

import { List } from "../List";
import { PositionItem } from "../PositionItem";
import { Search } from "../Search";
import * as S from "./styles";
import { Flex } from "@ifood/pomodoro-components";

type ChooseProps = {
  onAutocompleteAddress: (address: AutocompleteAddress) => void;
  showHeader?: boolean;
  showPreviousAddresses?: boolean;
};

export const Choose: React.FC<ChooseProps> = ({
  onAutocompleteAddress,
  showHeader,
  showPreviousAddresses,
}) => {
  const { latitude, longitude } = usePosition(true);

  const position = useMemo(() => {
    if (!latitude || !longitude) {
      return;
    }

    return new Coordinate(latitude, longitude);
  }, [latitude, longitude]);

  return (
    <S.Wrapper>
      <S.Body>
        <S.Container>
          <Search
            position={position}
            onAutocompleteAddress={onAutocompleteAddress}
          />
          <PositionItem
            position={position}
            onAutocompleteAddress={onAutocompleteAddress}
          />
        </S.Container>
        <S.Addresses>{showPreviousAddresses && <List />}</S.Addresses>
      </S.Body>
    </S.Wrapper>
  );
};

import {
  Flex,
  Icon as PomodoroIcon,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

type Themed = { theme: any };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Address = styled(Flex)`
  gap: 10px;
  align-items: center;
  width: -webkit-fill-available;
  margin-top: ${({ theme }) => theme.space.small};

  padding: ${({ theme }) => theme.space.regular};
  @media (${belowDesktop}) {
    padding: ${({ theme }) => theme.space.small};
  }

  color: ${({ theme }) => theme.colors.black};

  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  border-radius: 12px;
  cursor: pointer;

  &:nth-child(1) {
    margin-top: 0px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.black};
`;

export const LocationDetails = styled(Text)`
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.s};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const Details = styled(Flex)`
  align-items: center;
`;

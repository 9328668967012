import { Flex, Heading, TextLink as PomodoroTextLink, Text } from '@whitelabel-webapp/shared/design-system';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  padding: ${({ theme }) => theme.space.largest};
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: ${({ theme }) => theme.space.larger};

  margin-bottom: ${({ theme }) => theme.space.large};
`;

export const Description = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.grayDarkest};

  margin-bottom: ${({ theme }) => theme.space.largest};
`;

export const TextLink = styled(PomodoroTextLink)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
`;

import {
  Flex,
  Icon as PomodoroIcon,
  Text,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

type Themed = { theme: any };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Flex)`
  min-height: 64px;
  margin-top: ${({ theme }) => theme.space.smaller};
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const Container = styled(Flex)`
  margin-right: ${({ theme }) => theme.space.smaller};
  margin-left: ${({ theme }) => theme.space.smaller};
`;

export const Icon = styled(PomodoroIcon)`
  margin-right: ${({ theme }) => theme.space.smaller};
  margin-left: ${({ theme }) => theme.space.smaller};

  color: ${({ theme }) => theme.colors.grayDarker};
`;

export const Label = styled(Text)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.black};
`;

export const Description = styled(Text)`
  margin-bottom: ${({ theme }) => theme.space.smaller};
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  color: ${({ theme }) => theme.colors.grayDarker};
  line-height: ${({ theme }) => theme.lineHeights.s};

  @media (${belowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

import { addressAboyeur } from '@whitelabel-webapp/address/shared/config';
import { AutocompleteAddress, AutocompleteAddresses, Coordinate } from '@whitelabel-webapp/address/shared/models';
import { Location } from '@whitelabel-webapp/shared/design-system';
import { useEffect, useState } from 'react';

import * as S from './styles';

type PositionItemProps = {
  onAutocompleteAddress: (autocompleteAddress: AutocompleteAddress) => void;
  position?: Coordinate;
};

export const PositionItem: React.VFC<PositionItemProps> = ({ position, onAutocompleteAddress }) => {
  const [nearAutocompleteAddress, setNearAutocompleteAddress] = useState<AutocompleteAddress>();

  useEffect(() => {
    async function fetchNearAutocompleteAddress() {
      if (!position) return;

      const addresses = AutocompleteAddresses.fromApi(
        await AutocompleteAddresses.getAddressesByReverseGeocode(position.latitude, position.longitude)
      );

      if (!addresses || !addresses.list) {
        return;
      }

      setNearAutocompleteAddress(addresses.list[0]);
    }

    fetchNearAutocompleteAddress();
  }, [position]);

  function handleClick(value: AutocompleteAddress | undefined): void {
    if (!value) {
      return;
    }

    addressAboyeur.events.details.useMyLocation();
    onAutocompleteAddress(value);
  }

  return (
    <S.Wrapper centered onClick={() => handleClick(nearAutocompleteAddress)}>
      <S.Icon component={Location} size="s" />
      <S.Container flexDirection="column" flexGrow={1}>
        {position && (
          <>
            <S.Label>Localização atual</S.Label>
            <S.Description>
              {nearAutocompleteAddress
                ? `${nearAutocompleteAddress.streetName} - ${nearAutocompleteAddress.neighborhood} - ${nearAutocompleteAddress.city}`
                : 'Carregando...'}
            </S.Description>
          </>
        )}
        {!position && <S.Label>Localização atual desativada</S.Label>}
      </S.Container>
    </S.Wrapper>
  );
};

import SVG from 'react-inlinesvg';

import * as S from './styles';

type NotFoundProps = {
  onChangeAddress: () => void;
};

export const NotFound: React.VFC<NotFoundProps> = ({ onChangeAddress }) => {
  return (
    <S.Wrapper>
      <SVG src="/images/address/missing_location.svg" />
      <S.Title>Endereço fora da área de entrega</S.Title>
      <S.Description>Ainda não entregamos no seu endereço.</S.Description>
      <S.Description>Você pode tentar outro endereço.</S.Description>
      <S.TextLink onClick={onChangeAddress}>Trocar endereço</S.TextLink>
    </S.Wrapper>
  );
};

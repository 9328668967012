import { yupResolver } from "@hookform/resolvers/yup";
import { addressAboyeur } from "@whitelabel-webapp/address/shared/config";
import {
  AutocompleteAddress,
  AutocompleteAddresses,
  CustomerAddress,
} from "@whitelabel-webapp/address/shared/models";
import {
  Flex,
  Grid,
  Heading,
  Input,
  PinOutlined,
  snackbar,
} from "@whitelabel-webapp/shared/design-system";
import { ecommerceEvents } from "@whitelabel-webapp/shared/ecommerce-events";
import { isStringEqual } from "@whitelabel-webapp/shared/string-utils";
import React, { useMemo, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import * as yup from "yup";

import * as S from "./styles";

type AddProps = {
  autocompleteAddress: AutocompleteAddress;
  onAddAddress: (customerAddress: CustomerAddress) => Promise<void>;
  onAddAddressError?: () => void;
  isEditable?: boolean;
};

export const Add: React.VFC<AddProps> = ({
  autocompleteAddress,
  onAddAddress,
  onAddAddressError,
  isEditable,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      neighborhood: yup.string().required("Bairro é um campo obrigatório."),
      address: yup.string().required("Endereço é um campo obrigatório."),
      // TODO: Remove this max validation after fix the bug in backend
      streetNumber: yup
        .string()
        .max(9)
        .required("Número é um campo obrigatório."),
      complement: yup.string(),
      reference:
        autocompleteAddress?.rules &&
        autocompleteAddress.rules.isReferenceMandatory()
          ? yup.string().required("Ponto de referência é um campo obrigatório.")
          : yup.string(),
    });
  }, [autocompleteAddress]);

  const addForm = useForm({
    defaultValues: {
      neighborhood: autocompleteAddress.neighborhood ?? "",
      address: autocompleteAddress.streetName ?? "",
      streetNumber: autocompleteAddress.streetNumber ?? "",
      complement: "",
      reference: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const { isSubmitted, isValid } = useFormState({ control: addForm.control });

  const shouldDisableSubmitButton = isSubmitted && !isValid;

  const address = addForm.watch("address");
  const streetNumber = addForm.watch("streetNumber");

  const onSubmit = async (values: {
    address: string;
    neighborhood: string;
    streetNumber: string;
    reference: string;
    complement: string;
  }) => {
    try {
      setIsLoading(true);

      let customerAddress: CustomerAddress;

      if (
        !isStringEqual(autocompleteAddress.streetName, values.address) ||
        !isStringEqual(autocompleteAddress.neighborhood, values.neighborhood) ||
        !isStringEqual(
          autocompleteAddress.streetNumber || "",
          values.streetNumber,
        )
      ) {
        const { addresses } = await AutocompleteAddresses.getAddressesByGeocode(
          `${values.address}, ${values.streetNumber} - ${values.neighborhood}, ${autocompleteAddress.city} - ${autocompleteAddress.state}, ${autocompleteAddress.country}`,
        );
        const nearAddress = addresses[0];

        customerAddress = new CustomerAddress(
          nearAddress.country,
          nearAddress.state,
          nearAddress.city,
          values.neighborhood,
          values.address,
          nearAddress.coordinates,
          nearAddress.postalCode,
          values.reference,
          values.streetNumber,
          values.complement,
        );
      } else {
        customerAddress = new CustomerAddress(
          autocompleteAddress.country,
          autocompleteAddress.state,
          autocompleteAddress.city,
          values.neighborhood,
          values.address,
          autocompleteAddress.coordinates,
          autocompleteAddress.postalCode,
          values.reference,
          values.streetNumber,
          values.complement,
        );
      }

      if (
        !customerAddress.coordinates ||
        !customerAddress.coordinates.latitude ||
        !customerAddress.coordinates.longitude
      ) {
        throw new Error(
          "Algo deu errado!, não foi possível estabelecer as coordenadas de sua localização.",
        );
      }

      await onAddAddress(customerAddress);
      addressAboyeur.events.details.addAddress();
      ecommerceEvents.shippingInfo();
    } catch (error: any) {
      addressAboyeur.events.catch.onError(error as Error);

      setIsLoading(false);

      if (!error?.isAxiosError) {
        snackbar({
          variant: "error",
          message: error.message,
        });

        return;
      }

      onAddAddressError?.();
    }
  };

  return (
    <S.Wrapper>
      <S.InfoContainer>
        <S.Icon component={PinOutlined} size="m" />
        <Flex flexDirection="column">
          <S.Label>
            {[address, streetNumber].filter(Boolean).join(", ")}
          </S.Label>
          <S.Description>{`${autocompleteAddress.city}, ${autocompleteAddress.state}`}</S.Description>
        </Flex>
      </S.InfoContainer>
      <form onSubmit={addForm.handleSubmit(onSubmit)}>
        <S.Container>
          <Input
            {...addForm.register("neighborhood")}
            name="neighborhood"
            label="Bairro*"
            error={addForm.formState.errors.neighborhood?.message || ""}
          />
        </S.Container>
        <S.Container>
          <Input
            {...addForm.register("address")}
            name="address"
            label="Endereço*"
            error={addForm.formState.errors.address?.message || ""}
            disabled={!isEditable}
          />
        </S.Container>
        <S.Container>
          <Grid gridTemplateColumns="auto auto" gridGap={23}>
            <S.HalfInput
              // TODO: Remove this max validation after fix the bug in backend
              maxLength={9}
              {...addForm.register("streetNumber")}
              name="streetNumber"
              label="Número*"
              error={addForm.formState.errors.streetNumber?.message || ""}
            />
            <div>
              <S.HalfInput
                {...addForm.register("complement")}
                name="complement"
                label="Complemento"
                error={addForm.formState.errors.complement?.message || ""}
              />
            </div>
          </Grid>
        </S.Container>
        <S.Container>
          <Input
            {...addForm.register("reference")}
            name="reference"
            label={`Ponto de referência${
              autocompleteAddress.rules?.isReferenceMandatory() ? "*" : ""
            }`}
            placeholder="Ex.: Em frente ao posto de gasolina"
            error={addForm.formState.errors.reference?.message || ""}
          />
        </S.Container>
        <S.Container>
          <S.Subtitle>*Obrigatório</S.Subtitle>
        </S.Container>
        <S.Button
          type="submit"
          isLoading={isLoading}
          disabled={shouldDisableSubmitButton}
        >
          Usar endereço
        </S.Button>
      </form>
    </S.Wrapper>
  );
};

import { History, PinOutlined } from "@ifood/pomodoro-icons";
import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { CustomerAddress } from "@whitelabel-webapp/address/shared/models";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { DeliveryMethod } from "@whitelabel-webapp/merchant/shared/models";
import { useCallback } from "react";

import * as S from "./styles";

type AddressItemProps = {
  address: CustomerAddress;
};

export const AddressItem: React.VFC<AddressItemProps> = ({ address }) => {
  const { merchant } = useMerchant();
  const { closeAddress, setAddress, setDeliveryMethod } = useAddress();

  const handleSetAddress = useCallback(async () => {
    const deliveryMethodResponse = await merchant.getDeliveryMethod(
      address.coordinates.latitude,
      address.coordinates.longitude,
    );

    if (!deliveryMethodResponse) {
      return;
    }

    const deliveryMethod = DeliveryMethod.fromApi(deliveryMethodResponse);
    setAddress("MERCHANT", address);
    setDeliveryMethod(deliveryMethod);
    closeAddress();
  }, [address, merchant, setAddress, setDeliveryMethod, closeAddress]);

  return (
    <S.Address role="button" tabIndex={0} onClick={handleSetAddress}>
      <S.Icon component={History} size="s" />
      <S.Details>
        <S.LocationDetails>{`${address.streetName}, ${address.streetNumber} - ${address.city}, ${address.state}`}</S.LocationDetails>
      </S.Details>
    </S.Address>
  );
};

import { Chain, ChainResponse } from '@whitelabel-webapp/chain/shared/models';
import { createContext, useContext, useMemo, useState } from 'react';

type ChainContext = {
  chain: Chain;
};

export const ChainContext = createContext<ChainContext | undefined>(undefined);

export type ChainProviderProps = {
  chainResponse: ChainResponse;
};

export const ChainProvider: React.FC<ChainProviderProps> = ({ children, chainResponse }) => {
  const chain = useMemo(() => Chain.fromApi(chainResponse), [chainResponse]);

  const [value] = useState(() => {
    return { chain };
  });

  return <ChainContext.Provider value={value}>{children}</ChainContext.Provider>;
};

ChainProvider.displayName = 'ChainProvider';

export function useChain(): ChainContext {
  const context = useContext(ChainContext);

  if (typeof context === 'undefined') {
    throw new Error(`'useChain()' must be used within a ${ChainProvider.displayName}`);
  }

  return context;
}

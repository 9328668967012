import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import { DetailsWithChain } from "../DetailsWithChain";
import { DetailsWithMerchant } from "../DetailsWithMerchant";

export const DeliveryAddress: React.VFC = () => {
  const { addressConfig } = useAddress();

  if (!addressConfig.isOpen) return null;

  return (
    <>
      {addressConfig.type === "CHAIN" && <DetailsWithChain />}
      {addressConfig.type === "MERCHANT" && <DetailsWithMerchant />}
    </>
  );
};

import { Heading, Text } from "@ifood/pomodoro-components";
import { Drawer, Flex } from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

type Themed = { theme: any };
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 342px;
  overflow-y: auto;
`;

export const Body = styled(Drawer.Body)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Header = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: ${({ theme }) => theme.space.largest};
  text-align: center;
`;

export const Title = styled(Heading)`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;

  @media (${belowDesktop}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const Subtitle = styled(Text)`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDarkest};

  @media (${belowDesktop}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 1px;
  flex-direction: column;
`;

export const Addresses = styled.div`
  flex-grow: 1;
`;

export const Footer = styled(Drawer.Footer)`
  gap: ${({ theme }) => theme.space.regular};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
